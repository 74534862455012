.product-container {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: 1.5rem; */
}

.product-header {
  top: -121px;
  margin-bottom: -121px;
  height: 750px;
  width: 100%;
  position: relative;
  z-index: -1;
}

.product-header img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: 50% 20%;
  filter: blur(1.5px);
  filter: brightness(40%);
  z-index: 0;
}

.product-header .product-header_text {
  z-index: 1;
  top: 300px;
  position: relative;
  text-align: center;
  color: var(--light-color);
  padding: 50px;
  font-weight: bolder;
  font-size: x-large;
}

.product-miniDiv {
  position: relative;
  width: 45%;
  margin: auto;
  /* text-align: center; */
  color: #000;
  white-space: pre-wrap;
  /* padding: 50px; */
}

.product-miniDiv h1 {
  text-align: center;
  /* padding: 50px; */
}

.product-miniDiv_sub {
  text-align: center;
}
.product-miniDiv h2 {
  color: #47778e;
  /* padding: 50px; */
}
.product-divider {
  position: relative;
  /* width: 100%; */
  margin: auto;
  text-align: center;
  padding: 50px;
  z-index: -1;
}

.product-divider img {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0px;
  position: absolute;
  object-fit: cover;
  object-position: center;
  /* background-position: top; */
  filter: blur(2.5px);
  filter: brightness(15%);
  z-index: 0;
}

.product-divider h1 {
  z-index: 1;
  /* top: 500px; */
  position: relative;
  text-align: center;
  color: #fff;
  padding: 50px;
}

/* .product-divider h1 {
  color: var(--mid-color);
} */

@media screen and (max-width: 845px) {
  .product-miniDiv {
    width: 85%;
  }
}
