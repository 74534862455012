.contactUs-container {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: 1.5rem; */
}

.contactUs-header {
  top: -121px;
  margin-bottom: -121px;
  height: 400px;
  width: 100%;
  position: relative;
  z-index: -1;
}

.contactUs-header img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
  object-position: 50% 20%;
  filter: blur(1.5px);
  filter: brightness(40%);
  z-index: 0;
}

.contactUs-header .contactUs-header_text {
  z-index: 1;
  top: 200px;
  position: relative;
  text-align: center;
  color: var(--light-color);
  padding: 50px;
  font-weight: bolder;
  font-size: x-large;
}

.contactUs-divider {
  position: relative;
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 50px;
}

.contactUS-form_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10rem;
  padding: 2.5rem;
}

.container1 {
  display: grid;
  grid-template-columns: 1fr;
}

.container1 input {
  margin-bottom: 1rem;
}

.container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2rem;
}

.contactUS-map_row {
  margin: auto;
  width: 100%;
  /* border: 3px solid green; */
  padding: 10px;
  padding: 2rem;
}

iframe {
  width: 70%;
  height: 500px;
  display: block;
  margin: auto;
}

.submit_btn {
  padding: 10px 20px;
  border: none;
  font-size: 12px;
  color: var(--light-color);
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  cursor: pointer;
}

.submit_btn {
  margin: 3rem;
  padding: 1rem;
  background: var(--mid-color);
  box-shadow: 0 0 25px var(--hover-color);
}

.submit_btn:hover {
  transition: background-color 0.5s ease;
  background: var(--dark-color);
  box-shadow: 0 0 5px var(--brand-color), 0 0 25px var(--brand-color),
    0 0 50px var(--brand-color), 0 0 100px var(--brand-color);
}
