.home-container {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: 1.5rem; */
}

.main-header {
  top: -121px;
  margin-bottom: -121px;
  height: 750px;
  width: 100%;
  position: relative;
  z-index: -1;
  background-color: var(--dark-color);
}

.main-header video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  filter: blur(1.5px);
  filter: brightness(45%);
  z-index: 0;
}

.main-header .main-header_text {
  z-index: 1;
  top: 500px;
  position: relative;
  text-align: left;
  color: var(--light-color);
  padding: 50px;
  font-size: large;
}

.main-divider {
  position: relative;
  width: 60%;
  margin: auto;
  /* margin-top: -120px; */
  text-align: center;
  /* background-color: #fff6f6; */
  padding: 50px;
  /* padding-top: 120px; */
}

.footer-divider {
  position: relative;
  /* margin-top: -120px; */
  text-align: center;
  /* background-color: #fff6f6; */
  /* padding: 50px; */
  /* padding-top: 120px; */
  width: 100%;
  position: relative;
  z-index: -1;
}

.footer-divider video {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  object-fit: cover;
  object-position: top;
  filter: blur(2.5px);
  filter: brightness(15%);
  z-index: 0;
}

.footer-divider .footer-divider_text {
  z-index: 1;
  /* top: 500px; */
  position: relative;
  text-align: center;
  color: var(--light-color);
  padding: 50px;
}

@media (max-width: 900px) {
  .main-header_text {
    top: 450px !important;
  }
}
