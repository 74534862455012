.dualLink-hero {
  z-index: 1;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
  padding: 5rem;
  width: 100%;
  max-width: 1200px;
}

.dualLink-col {
  top: -121px;
  margin-bottom: -121px;
  height: 750px;
  width: 100%;
  /* max-width: 700px; */
  position: relative;
  z-index: -1;
}

.dualLink-col img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: 50% 20%;
  filter: blur(1.5px);
  filter: brightness(80%);
  z-index: -1;
}

.dualLink-col .dualLink-hero_btn {
  top: 300px;
  position: relative;
  text-align: center;
  color: var(--light-color);
  padding: 50px;
  font-weight: bolder;
  font-size: x-large;
}

.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 12px;
  color: var(--light-color);
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  cursor: pointer;
}

.shadow__btn {
  background: var(--mid-color);
  box-shadow: 0 0 25px var(--hover-color);
}

.shadow__btn:hover {
  transition: background-color 0.5s ease;
  background: var(--dark-color);
  box-shadow: 0 0 5px var(--brand-color), 0 0 25px var(--brand-color),
    0 0 50px var(--brand-color), 0 0 100px var(--brand-color);
}

@media screen and (max-width: 845px) {
  .dualLink-hero {
    grid-template-columns: 1fr;
    grid-row-gap: 5rem;
    padding: 3rem;
    width: 70%;
  }
  .dualLink-col {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-column-gap: 0rem; */
  }

  .dualLink-hero_btn {
    top: 200px !important;
    /* padding-left: 3rem !important; */
  }
  .shadow__btn {
    width: 100%;
  }
  .dualLink-col img {
    height: 85%;
    width: 95%;
  }
}
