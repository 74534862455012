.main-heroXL {
  text-align: center;
  align-items: center;
  width: 100%;
  height: 90%;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  /* grid-column-gap: 1.5rem; */
}

.main-heroXL_image-container {
  height: 100%;
}

.main-heroXL_img {
  height: 720px;
  width: 960px;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: -3.5px;
}

.main-heroXL_text {
  padding: 18px;
  font-size: 15px;
}

.main-heroXL_text h4 {
  text-align: left;
  white-space: pre-wrap;
}

.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 12px;
  color: var(--light-color);
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
}

.shadow__btn {
  background: var(--mid-color);
  box-shadow: 0 0 25px var(--hover-color);
}

.shadow__btn:hover {
  transition: background-color 0.5s ease;
  background: var(--dark-color);
  box-shadow: 0 0 5px var(--brand-color), 0 0 25px var(--brand-color),
    0 0 50px var(--brand-color), 0 0 100px var(--brand-color);
}

@media screen and (max-width: 845px) {
  .main-heroXL {
    /* width: 100%; */
    grid-template-columns: 1fr;
    /* grid-column-gap: 1.5rem; */
  }
  .main-heroXL_img {
    height: 500px;
  }
}
