.heroDualText-container {
  display: flex;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.heroDualText-hero {
  width: 88%;
  padding: 50px;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  margin: auto 100px;

  /* grid-column-gap: 1.5rem; */
}

.heroDualText-hero_text {
  padding: 50px;
  width: 60%;
  border-radius: 54px;
  /* background: #144578; */
  box-shadow: 2.8px 2.8px 1px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 2.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 4.4px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 7.8px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 14.6px rgba(0, 0, 0, 0.05),
    100px 100px 35px rgba(0, 0, 0, 0.07);
}

.heroDualText-hero_text h3 {
  text-align: left;
  white-space: pre-wrap;
}

.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 12px;
  color: var(--light-color);
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
}

.shadow__btn {
  background: var(--mid-color);
  box-shadow: 0 0 25px var(--hover-color);
}

.shadow__btn:hover {
  transition: background-color 0.5s ease;
  background: var(--dark-color);
  box-shadow: 0 0 5px var(--brand-color), 0 0 25px var(--brand-color),
    0 0 50px var(--brand-color), 0 0 100px var(--brand-color);
}

@media screen and (max-width: 845px) {
  .heroDualText-hero {
    width: 74%;
    height: 50%;
    grid-template-columns: 1fr;
    margin: auto;

    /* grid-column-gap: 1.5rem; */
  }
  .heroDualText-hero_text {
    font-size: small;
  }
}
